import { createTestingPinia } from '@pinia/testing';
import { shallowMount } from '@vue/test-utils';
import { setActivePinia } from 'pinia';

import Component from './ReturnsCard.vue';

let wrapper;

describe('ReturnsCard', () => {
  beforeEach(() => {
    setActivePinia(
      createTestingPinia({
        initialState: {
          orders: {
            currentOrder: {
              settings: {
                returns: {
                  returnUrl: 'https://www.google.com',
                  trackingUrl: 'https://www.google.com',
                },
              },
            },
          },
        },
      }),
    );
    wrapper = shallowMount(Component, {
      props: {
        items: [],
      },
    });
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });
});
