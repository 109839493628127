import revive_payload_client_gO6Exf5LV8 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HbREIlp05T from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6ci1w6chLh from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_GIF8ZRItuB from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QEygw1UMDc from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_d7NHE3IZ5n from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8xdgJu4SNW from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.28.0_typescript@5.0.4_vue@3.5.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/concierge/.nuxt/components.plugin.mjs";
import prefetch_client_ZIxnBBQHqY from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_zXvfkBdnZ0 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.28.0_vue@3.5.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ME7RNJwPMJ from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.28.0_vue@3.5.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import vue_easy_lightbox_709h0kYieC from "/app/apps/concierge/src/modules/runtime/vue-easy-lightbox.ts";
import hubble_lB3djeYGL7 from "/app/apps/concierge/src/plugins/hubble.js";
import sentry_sCeXubc6q1 from "/app/apps/concierge/src/plugins/sentry.js";
export default [
  revive_payload_client_gO6Exf5LV8,
  unhead_HbREIlp05T,
  router_6ci1w6chLh,
  payload_client_GIF8ZRItuB,
  navigation_repaint_client_QEygw1UMDc,
  chunk_reload_client_d7NHE3IZ5n,
  plugin_vue3_8xdgJu4SNW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZIxnBBQHqY,
  switch_locale_path_ssr_zXvfkBdnZ0,
  i18n_ME7RNJwPMJ,
  vue_easy_lightbox_709h0kYieC,
  hubble_lB3djeYGL7,
  sentry_sCeXubc6q1
]