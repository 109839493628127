import { ClaimModel } from '@pulse/models';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { CREDIT_BONUS_TYPES } from '~/support/constants';
import { OrderService } from '~/support/services';

import { useClaimStore } from './claim';

export const useOrdersStore = defineStore('orders', () => {
  const currentOrder = ref(null);
  const claim = useClaimStore();
  const { currentShipmentId } = storeToRefs(claim);
  const service = new OrderService(useRuntimeConfig().public.apiBaseUrl);
  const selectedItems = ref([]);
  const itemEligibility = ref({});

  async function find(email, orderNumber) {
    currentOrder.value = await service.find(email, orderNumber);

    claim.setCustomer({
      email,
      sourceOrderId: orderNumber,
    });

    itemEligibility.value = currentOrder.value.eligibility;

    return currentOrder.value;
  }

  function reset() {
    currentOrder.value = null;
  }

  async function fetchOrder(email, sourceOrderId) {
    const dbOrder = await find(email, sourceOrderId);

    if (!dbOrder) return;

    currentOrder.value = dbOrder;
  }

  const claimFeedClaims = computed(() => {
    if (!currentOrder.value.existingClaims) {
      return [];
    }

    return ClaimModel.fromArrayExtending(currentOrder.value?.existingClaims, {
      order: currentOrder.value,
    });
  });

  const formattedCreditBonus = computed(() => {
    if (currentOrder.value.settings.credit.bonus === null) return null;

    return currentOrder.value.settings.credit.bonusType === CREDIT_BONUS_TYPES.fixed
      ? new Intl.NumberFormat(navigator.language, {
          currency: currentOrder.value.currency,
          style: 'currency',
        }).format(currentOrder.value.settings.credit.bonus * currentOrder.value.exchangeRate)
      : `${currentOrder.value.settings.credit.bonus}%`;
  });

  const soonestFileDate = computed(() => {
    const [date] = currentOrder.value.fulfillments.map(({ eligibleAt }) => eligibleAt).sort();

    return date;
  });

  const eligibleFulfillments = computed(() => {
    return currentOrder.value.fulfillments.filter(({ eligible }) => eligible);
  });

  const tooSoonToFile = computed(() => {
    if (currentOrder.value.fulfillments.length === 0) {
      return false;
    }

    return currentOrder.value.fulfillments.every(({ eligible }) => !eligible);
  });

  /**
   * TODO - add logic
   */
  const tooLateToFile = computed(() => {
    return (
      currentOrder.value.fulfillments[0].eligible === false &&
      currentOrder.value.fulfillments[0].eligibilityCode === 'expired'
    );
  });

  const eligibleForClaim = computed(() => {
    if (!currentOrder.value) return false;

    return currentOrder.value.protected;
  });

  const allCurrentOrderItems = computed(() => {
    if (!currentOrder.value) return [];

    return currentOrder.value.fulfillments.reduce((all, fulfillment) => {
      return all.concat(
        fulfillment.items.map(({ item, quantity }) => ({
          ...item,
          quantity,
        })),
      );
    }, []);
  });

  const deliveredFulfillments = computed(() => {
    if (!currentOrder.value) return [];

    return currentOrder.value.fulfillments.filter(({ fulfillmentStatus }) => fulfillmentStatus === 'DELIVERED');
  });

  const hasDeliveredFulfillments = computed(() => {
    return deliveredFulfillments.value.length > 0;
  });

  const currentShipmentOrderItems = computed(() => {
    if (!currentOrder.value?.fulfillments?.length > 0 || !currentShipmentId.value) {
      return [];
    }

    return currentOrder.value.fulfillments
      .find(({ sourceFulfillmentId }) => sourceFulfillmentId === currentShipmentId.value)
      .items.map(({ item, quantity, sourceFulfillmentId }) => ({
        ...item,
        quantity,
        sourceFulfillmentId,
      }));
  });

  const originalAddress = computed(() => {
    return currentOrder.value.shippingAddress;
  });

  const orderHasShipped = computed(() => {
    return currentOrder.value.fulfillments.length > 0;
  });

  const isOta = computed(() => {
    return currentOrder.value.isOneTimeAppeasement;
  });

  const supportsReturns = computed(() => {
    return currentOrder.value && !!currentOrder.value.settings?.capablities?.returns;
  });

  const returns = computed(() => {
    return currentOrder.value.returns ?? [];
  });

  const claims = computed(() => {
    return currentOrder.value.existingClaims ?? [];
  });

  const hasEligibleItems = computed(() => {
    return Object.values(itemEligibility.value).some(value => value > 0);
  });

  const returnsUrl = computed(() => {
    return currentOrder.value.settings.returns.portalUrl;
  });

  const trackingUrl = computed(() => {
    return currentOrder.value.settings.returns.trackingUrl;
  });

  return {
    allCurrentOrderItems,
    claimFeedClaims,
    claims,
    currentOrder,
    currentShipmentOrderItems,
    deliveredFulfillments,
    eligibleForClaim,
    eligibleFulfillments,
    fetchOrder,
    find,
    formattedCreditBonus,
    hasDeliveredFulfillments,
    hasEligibleItems,
    isOta,
    itemEligibility,
    orderHasShipped,
    originalAddress,
    reset,
    returns,
    returnsUrl,
    selectedItems,
    soonestFileDate,
    supportsReturns,
    tooLateToFile,
    tooSoonToFile,
    trackingUrl,
  };
});
