export const ORDER_ITEM = {
  address: {},
  description: null,
  image: 'https://picsum.photos/seed/orderprotection1/200/300',
  images: [],
  item: {
    sku: 'FLAIR-123',
  },
  link: 'https://bogus-clarity.name/',
  name: 'Handcrafted Wooden Table',
  price: 31.5,
  quantity: 5,
  sku: 'FLAIR-123',
  sourceItemId: '1234',
  sourceProductId: '477353',
  sourceVariantId: '17742',
};

export const ORDER_WITH_ITEMS = {
  customerEmail: 'john@doe.com',
  customerName: 'This Me',
  discountTotal: 1.2,
  existingClaims: [],
  fulfillments: [
    {
      createdAt: '2024-05-08T16:20:55.024Z',
      deletedAt: null,
      eligibilityCode: null,
      eligible: true,
      eligibleAt: '2024-05-10T00:00:00.000Z',
      fulfillmentStatus: 'DELIVERED',
      id: 'clvy10ktr000ginll77is6qwq',
      items: [
        {
          fulfillmentId: 'clvy10ktr000ginll77is6qwq',
          id: 'clvy10kts000hinllyhqngeu7',
          item: {
            addressId: null,
            createdAt: '2024-05-08T16:19:26.180Z',
            discount: 0,
            id: 'clvy0yo9w0007inll8l1r7124',
            itemDescription: 'Variant: CRACKED OUT',
            orderId: 'clvy0yo9w0004inllzlaa4c0f',
            price: 144.75,
            productImage: 'https://picsum.photos/seed/ijoj4dd/640/480',
            productName: 'Ric Flair Poster',
            productUrl: 'https://yourdomain.com/products/wooden-table',
            quantity: 2,
            refundedQuantity: 0,
            sku: 'FLAIR-123',
            sourceItemId: '1234',
            sourceProductId: '123456744',
            sourceVariantId: '12345644',
            tax: 0,
            updatedAt: '2024-05-08T16:19:26.180Z',
          },
          orderItemId: 'clvy0yo9w0007inll8l1r7124',
          quantity: 2,
        },
      ],
      orderId: 'clvy0yo9w0004inllzlaa4c0f',
      sourceFulfillmentId: 'split-shipment-2',
      sourceOrderId: '4',
      trackingCompany: 'USPS',
      trackingNumber: ['1234567'],
      trackingUrl: ['https://usps.com/track/1234567'],
      updatedAt: '2024-05-08T16:22:58.935Z',
    },
    {
      createdAt: '2024-05-08T16:20:43.083Z',
      deletedAt: null,
      eligibilityCode: null,
      eligible: true,
      eligibleAt: '2024-05-10T00:00:00.000Z',
      fulfillmentStatus: 'DELIVERED',
      id: 'clvy10bm2000binllknxvipbx',
      items: [
        {
          fulfillmentId: 'clvy10bm2000binllknxvipbx',
          id: 'clvy10bm2000cinllg1ku51p3',
          item: {
            addressId: null,
            createdAt: '2024-05-08T16:19:26.180Z',
            discount: 0,
            id: 'clvy0yo9w0006inlliwt4296b',
            itemDescription: "Variant: White Oak. Size 10'X10'",
            orderId: 'clvy0yo9w0004inllzlaa4c0f',
            price: 144.75,
            productImage: 'https://picsum.photos/seed/ijoj4/640/480',
            productName: 'Handcrafted Wooden Table',
            productUrl: 'https://yourdomain.com/products/wooden-table',
            quantity: 1,
            refundedQuantity: 0,
            sku: 'TABLE-123',
            sourceItemId: '1234',
            sourceProductId: '1234567',
            sourceVariantId: '123456',
            tax: 0,
            updatedAt: '2024-05-08T16:19:26.180Z',
          },
          orderItemId: 'clvy0yo9w0006inlliwt4296b',
          quantity: 1,
        },
      ],
      orderId: 'clvy0yo9w0004inllzlaa4c0f',
      sourceFulfillmentId: 'split-shipment',
      sourceOrderId: '4',
      trackingCompany: 'USPS',
      trackingNumber: ['1234567'],
      trackingUrl: ['https://usps.com/track/1234567'],
      updatedAt: '2024-05-08T16:20:43.083Z',
    },
  ],
  order: { originalSourceOrderId: '3' },
  orderId: '4',
  orderNumber: '#4',
  protected: true,
  settings: {
    capabilities: {
      credit: true,
      refund: true,
      reship: true,
    },
    credit: {
      bonus: 2,
      bonusType: 'percentage',
      enabled: true,
    },
    metadata: null,
  },
  shipping: 3.5,
  shippingTax: 0,
  store: {},
  subtotal: 140.25,
  systemOrderId: 'clvy0yo9w0004inllzlaa4c0f',
  tax: 0.55,
  total: 150,
};
